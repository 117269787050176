import { navigateToUrl } from 'single-spa';
import { BaseRoutes } from './baseRoutes';
import { ROUTES } from './routes';

const prefix = (location: Location, ...prefixes: string[]): boolean =>
  prefixes.some(
    (prefix) => location.href.indexOf(`${location.origin}${prefix}`) !== -1,
  );

// Main roots
const mfForYou = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.FOR_YOU_FORM);

const mfSimulation = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.SIMULATION);

const mfRejected = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.REJECTED);

const mfPrecontact = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.PRECONTACT);

const mfDataSimulation = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.DATA_SIMULATION);

const mfCocredited = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.COCREDITED);

// Credit History Service
const mfPersonCHS = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.PERSON_CHS);

const mfAddressCHS = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.ADDRESS_CHS);

const mfPropertyForm = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.PROPERTY);

const mfNipCHS = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.NIP_CHS);

const mfFinalStep = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.FINAL_STEP);

const mfRedirect = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.CONTINUATION);

const mfStatusApp = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.STATUS_APP);

const notFound = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.NO_MATCH);

const mfRejectedCHS = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.REJECTED_CHS);

// Steps
const mfNavbar = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.HOME_API + ROUTES.FOR_YOU_FORM,
    BaseRoutes.HOME_API + ROUTES.SIMULATION,
    BaseRoutes.HOME_API + ROUTES.REJECTED,
    BaseRoutes.HOME_API + ROUTES.PRECONTACT,
    BaseRoutes.HOME_API + ROUTES.PERSON_CHS,
    BaseRoutes.HOME_API + ROUTES.ADDRESS_CHS,
    BaseRoutes.HOME_API + ROUTES.PROPERTY,
    BaseRoutes.HOME_API + ROUTES.NIP_CHS,
    BaseRoutes.HOME_API + ROUTES.FINAL_STEP,
    BaseRoutes.HOME_API + ROUTES.STATUS_APP,
    BaseRoutes.HOME_API + ROUTES.NO_MATCH,
    BaseRoutes.HOME_API + ROUTES.DATA_SIMULATION,
    BaseRoutes.HOME_API + ROUTES.LOADING_NIP,
    BaseRoutes.HOME_API + ROUTES.NEW_NIP,
    BaseRoutes.HOME_API + ROUTES.COCREDITED,
    BaseRoutes.HOME_API + ROUTES.CONTINUATION,
    BaseRoutes.HOME_API + ROUTES.REJECTED_CHS,
  );

const mfFooter = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.HOME_API + ROUTES.FOR_YOU_FORM,
    BaseRoutes.HOME_API + ROUTES.SIMULATION,
    BaseRoutes.HOME_API + ROUTES.REJECTED,
    BaseRoutes.HOME_API + ROUTES.PRECONTACT,
    BaseRoutes.HOME_API + ROUTES.PERSON_CHS,
    BaseRoutes.HOME_API + ROUTES.ADDRESS_CHS,
    BaseRoutes.HOME_API + ROUTES.PROPERTY,
    BaseRoutes.HOME_API + ROUTES.NIP_CHS,
    BaseRoutes.HOME_API + ROUTES.FINAL_STEP,
    BaseRoutes.HOME_API + ROUTES.STATUS_APP,
    BaseRoutes.HOME_API + ROUTES.DATA_SIMULATION,
    BaseRoutes.HOME_API + ROUTES.LOADING_NIP,
    BaseRoutes.HOME_API + ROUTES.NEW_NIP,
    BaseRoutes.HOME_API + ROUTES.COCREDITED,
    BaseRoutes.HOME_API + ROUTES.CONTINUATION,
    BaseRoutes.HOME_API + ROUTES.REJECTED_CHS,
  );

const mfStepper = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.HOME_API + ROUTES.PERSON_CHS,
    BaseRoutes.HOME_API + ROUTES.ADDRESS_CHS,
    BaseRoutes.HOME_API + ROUTES.PROPERTY,
    BaseRoutes.HOME_API + ROUTES.NIP_CHS,
  );

const mfBreadcrumb = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.HOME_API + ROUTES.FOR_YOU_FORM,
    BaseRoutes.HOME_API + ROUTES.SIMULATION,
    BaseRoutes.HOME_API + ROUTES.REJECTED,
    BaseRoutes.HOME_API + ROUTES.PRECONTACT,
    BaseRoutes.HOME_API + ROUTES.PERSON_CHS,
    BaseRoutes.HOME_API + ROUTES.ADDRESS_CHS,
    BaseRoutes.HOME_API + ROUTES.PROPERTY,
    BaseRoutes.HOME_API + ROUTES.NIP_CHS,
    BaseRoutes.HOME_API + ROUTES.FINAL_STEP,
    BaseRoutes.HOME_API + ROUTES.REJECTED_CHS,
  );

const mfLoadingPin = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.LOADING_NIP);

const mfNewPin = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.NEW_NIP);

const mfStepperPin = (location: Location): boolean =>
  prefix(location, BaseRoutes.HOME_API + ROUTES.NEW_NIP);

// We can add an auto redirect using SingleSpa.navigateToUrl
const main = (location: Location): boolean => {
  if (
    location.pathname === BaseRoutes.HOME_API ||
    location.pathname ===
      BaseRoutes.HOME_API.substr(0, BaseRoutes.HOME_API.length - 1) ||
    location.pathname === '/'
  ) {
    navigateToUrl(BaseRoutes.HOME_API + ROUTES.DATA_SIMULATION);
  }

  const pathExists = Object.values(ROUTES).find((route: string) => {
    const existsInHome = location.pathname === `${BaseRoutes.HOME_API}${route}`;
    return existsInHome;
  });

  if (!pathExists) {
    navigateToUrl(BaseRoutes.HOME_API + ROUTES.NO_MATCH);
  }

  return true;
};

export default {
  main,
  mfNavbar,
  mfStepper,
  mfForYou,
  mfSimulation,
  mfRejected,
  mfPrecontact,
  mfCocredited,
  mfPersonCHS,
  mfAddressCHS,
  mfPropertyForm,
  mfNipCHS,
  mfFinalStep,
  mfRejectedCHS,
  mfStatusApp,
  notFound,
  mfBreadcrumb,
  mfDataSimulation,
  mfLoadingPin,
  mfNewPin,
  mfStepperPin,
  mfRedirect,
  mfFooter,
};
