enum ROUTES {
  FOR_YOU_FORM = 'sobre-ti',
  SIMULATION = 'simulacion',
  REJECTED = 'sin-oferta',
  PRECONTACT = 'informacion',
  DATA_SIMULATION = 'datos-simulacion',
  PERSON_CHS = 'persona',
  ADDRESS_CHS = 'domicilio',
  PROPERTY = 'tu-inmueble',
  NIP_CHS = 'confirmacion-nip',
  FINAL_STEP = 'terminado',
  STATUS_APP = 'estatus-aplicacion',
  CONTINUATION = 'continuidad',
  LOADING_NIP = 'validacion',
  NEW_NIP = 'nueva-confirmacion',
  COCREDITED = 'coacreditado',
  NO_MATCH = '404',
  REJECTED_CHS = 'solicitud-descartada',
}

enum GO_TO_ROUTES {
  FINAL_STEP = '/prestamo/liquidez-hipotecaria',
  FAQ = 'https://creditasmx.zendesk.com/hc/es-419/sections/360010167511-Pr%C3%A9stamos-con-garant%C3%ADa-de-inmueble',
}

export { GO_TO_ROUTES, ROUTES };
