import { start, registerApplication, LifeCycles } from 'single-spa';
import Activity from './activityFns';
import * as serviceWorker from './serviceWorker';
import { GO_TO_ROUTES, ROUTES } from './routes';
import { BaseRoutes } from './baseRoutes';

const product = {
  name: 'Home',
  landingPageURL: `${process.env.REACT_APP_LANDING_URL}/prestamo/liquidez-hipotecaria`,
};

const registerApps = (): void => {
  registerApplication(
    'main',
    () => import('./microfrontends/app.lifecycles'),
    Activity.main,
  );
  registerApplication(
    'mfNavbar',
    () => import('./microfrontends/mfNavbar/mfNavbar.lifecycle'),
    Activity.mfNavbar,
  );
  registerApplication(
    'mfStepper',
    () => import('./microfrontends/mfStepper/mfStepper.lifecycle'),
    Activity.mfStepper,
    {
      baseRoute: BaseRoutes.HOME_API,
    },
  );
  registerApplication(
    'mfFooter',
    () => import('./microfrontends/mfFooter/mfFooter.lifecycle'),
    Activity.mfFooter,
  );
  registerApplication(
    'mfForYou',
    () => import('./microfrontends/mfForYou/mfForYou.lifecycle'),
    Activity.mfForYou,
    {
      goTo: BaseRoutes.HOME_API + ROUTES.SIMULATION,
      gotToCocredited: BaseRoutes.HOME_API + ROUTES.COCREDITED,
    },
  );
  registerApplication(
    'mfDataSimulation',
    () =>
      import('./microfrontends/mfDataSimulation/mfDataSimulation.lifecycle'),
    Activity.mfDataSimulation,
    {
      goTo: BaseRoutes.HOME_API + ROUTES.FOR_YOU_FORM,
    },
  );
  registerApplication(
    'mfSimulation',
    () => import('./microfrontends/mfSimulation/mfSimulation.lifecycle'),
    Activity.mfSimulation,
    {
      goTo: BaseRoutes.HOME_API + ROUTES.PRECONTACT,
      goToRejected: BaseRoutes.HOME_API + ROUTES.REJECTED,
    },
  );
  registerApplication(
    'mfRejected',
    () => import('./microfrontends/mfRejected/mfRejected.lifecycle'),
    Activity.mfRejected,
  );
  registerApplication(
    'mfPrecontact',
    () => import('./microfrontends/mfPrecontact/mfPrecontact.lifecycle'),
    Activity.mfPrecontact,
    {
      goTo: BaseRoutes.HOME_API + ROUTES.PERSON_CHS,
      backTo: BaseRoutes.HOME_API + ROUTES.SIMULATION,
    },
  );
  registerApplication(
    'mfPersonCHS',
    () => import('./microfrontends/mfPersonCHS/mfPersonCHS.lifecycle'),
    Activity.mfPersonCHS,
  );
  registerApplication(
    'mfAddressCHS',
    () => import('./microfrontends/mfAddressCHS/mfAddressCHS.lifecycle'),
    Activity.mfAddressCHS,
  );
  registerApplication(
    'mfProperty',
    () => import('./microfrontends/mfProperty/mfProperty.lifecycle'),
    Activity.mfPropertyForm,
  );
  registerApplication(
    'mfNipCHS',
    () => import('./microfrontends/mfNipCHS/mfNipCHS.lifecycle'),
    Activity.mfNipCHS,
  );
  registerApplication(
    'mfFinalStep',
    () => import('./microfrontends/mfFinalStep/mfFinalStep.lifecycle'),
    Activity.mfFinalStep,
    {
      goTo: GO_TO_ROUTES.FAQ,
      goToRejected: ROUTES.REJECTED_CHS,
    },
  );
  registerApplication(
    'mfRedirect',
    () => import('./microfrontends/mfRedirect/mfRedirect.lifecycle'),
    Activity.mfRedirect,
  );
  registerApplication(
    'mfApplication',
    () => import('./microfrontends/mfApplication/mfApplication.lifecycle'),
    Activity.mfStatusApp,
  );
  registerApplication(
    'mfLoadingPin',
    () => import('./microfrontends/mfLoadingPin/mfLoadingPin.lifecycle'),
    Activity.mfLoadingPin,
    {
      goTo: BaseRoutes.HOME_API + ROUTES.NEW_NIP,
    },
  );
  registerApplication(
    'mfNewPin',
    () => import('./microfrontends/mfNipCHS/mfNipCHS.lifecycle'),
    Activity.mfNewPin,
  );
  registerApplication(
    'mfStepperPin',
    () => import('./microfrontends/mfStepperPin/mfStepperPin.lifecycle'),
    Activity.mfStepperPin,
    {
      goTo: BaseRoutes.HOME_API + ROUTES.FINAL_STEP,
    },
  );
  registerApplication(
    'mfCocredited',
    () => import('./microfrontends/mfCocredited/mfCocredited.lifecycle'),
    Activity.mfCocredited,
  );
  registerApplication(
    'mfNotFound',
    () =>
      System.import('@creditasmx/notfound-mf') as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.notFound,
    {
      product,
      domElementId: 'renderContainerId',
    },
  );
  registerApplication(
    'mfRejectedCHS',
    () => import('./microfrontends/mfRejectedCHS/mfRejectedCHS.lifecycle'),
    Activity.mfRejectedCHS,
  );
};

registerApps();
start();

serviceWorker.unregister();
